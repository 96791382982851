import Select from "react-select";
import { React, useEffect, useState, useReducer } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import moment from "moment";

const BuffetOrder = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const divisionState = useSelector((state) => state.changeDivision);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isloading, setisloading] = useState(true);
  const userState = useSelector((state) => state.changeTheState.user);
  const loggedInUser = userState?.emailId?.split("@")[0]?.toUpperCase();
  const [clientsList, setClientlist] = useState([]);
  const [selClientName, setSelClientName] = useState("");
  const [selClientCode, setSelClientCode] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [search, setSearch] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const [buffetNo, setBuffetNo] = useState("");
  const [buffetDetails, setBuffetdetails] = useState([]);
  const [weight, setWeight] = useState("");
  const [unitRate, setUnitRate] = useState("");
  const [amount, setAmount] = useState("");
  const [grandtotal, setGrandtotal] = useState("");

  // Filters for Orders Pop Up
  const filtBuffet = buffetDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.ORDERDATE;
    const eDate = item.ORDERDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["ORDERNO", "CLIENTNAME"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  useEffect(() => {
    let total = 0;
    buffetDetails.forEach((item) => {
      total += parseFloat(item.AMOUNT);
    });
    setGrandtotal(total);
  }, [buffetDetails]);

  useEffect(() => {
    getNewBuffetNo();
    getListOfClients();
    getBuffetOrders();
  }, []);

  const getListOfClients = () => {
    axios
      .get(`${BASEURL}/getListOfClients`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setClientlist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of /getListOfClients:", error);
        setisloading(false);
      });
  };

  const getBuffetOrders = () => {
    axios
      .get(`${BASEURL}/getBuffetOrders`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setBuffetdetails(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Orders /getBuffetOrders:", error);
        setisloading(false);
      });
  };

  const getNewBuffetNo = () => {
    axios
      .get(`${BASEURL}/getNewBuffetNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setBuffetNo(response?.data[0]?.NEWBUFFETNO);
      })
      .catch((error) => {
        console.error("Error fetching List Of /getNewBuffetNo:", error);
        setisloading(false);
      });
  };

  // *********************** Save *********************
  async function SaveBuffet() {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/saveBuffetOrder`,
        {
          ORDERNO: Number(buffetNo),
          ORDERDATE: moment(date).format("YYYY-MM-DD"),
          ORDERTIME: moment(date).format("HH:MM:SS"),
          CLIENTCODE: selClientCode,
          WEIGHT: Number(weight),
          UNITRATE: Number(unitRate),
          AMOUNT: Number(amount),
          PROCESSEDBY: loggedInUser ? loggedInUser : "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert("Buffet Saved Successfully");
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.error(`Error while saving Buffet /saveBuffetOrder`, error);
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page">BUFFET ORDER</h1>
      </header>
      <div className="w-[100%] lg:flex flex-col items-center  justify-center">
        <div className="bg-slate-100 m-2 h-auto p-5 lg:w-[82%] rounded mt-4 shadow-lg">
          {/* 1st row */}
          <div className="lg:flex items-center gap-4">
            <div>
              <p>Buffet No</p>
              <input
                className="w-24 rounded-md h-7 text-right pr-2 font-bold"
                value={buffetNo}
                readOnly
              ></input>
            </div>
            {/* <button
              className="flex items-center mt-5 justify-center border border-black bg-[#002d62] text-white rounded-md px-2  bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                setOrderPopUp(true);
              }}
            >
              ...
            </button> */}
            <div>
              <p className="ml-2">Customer</p>
              <Select
                className="w-96 text-sm"
                onChange={(e) => {
                  if (e.value === "") {
                    setSelClientCode("");
                  } else {
                    setSelClientName(e.value);
                    const selectedOption = clientsList?.find(
                      (obj) => obj["CLIENT NAME"] === e.value
                    );
                    setSelClientCode(selectedOption?.["CLIENT CODE"]);
                  }
                }}
                value={
                  selClientName
                    ? { label: selClientName, value: selClientName }
                    : { label: "Select Customer", value: "" }
                }
                options={[
                  { label: "Select Customer", value: "" }, // Empty option
                  ...clientsList?.map((item) => ({
                    label: item["CLIENT NAME"],
                    value: item["CLIENT NAME"],
                  })),
                ]}
              />
            </div>
            <div>
              <p>Gram</p>
              <input
                type="number"
                className="w-24 rounded-md h-7 text-right pr-2 font-bold"
                value={weight}
                onChange={(e) => {
                  if (!unitRate) {
                    setWeight(e.target.value);
                  } else {
                    setWeight(e.target.value);
                    setAmount(Number(unitRate) * Number(e.target.value));
                  }
                }}
              ></input>
            </div>
            <div>
              <p>Rate per Gram</p>
              <input
                type="number"
                className="w-32 rounded-md h-7 text-right pr-2 font-bold"
                value={unitRate}
                onChange={(e) => {
                  if (!weight) {
                    setUnitRate(e.target.value);
                  } else {
                    setUnitRate(e.target.value);
                    setAmount(Number(weight) * Number(e.target.value));
                  }
                }}
              ></input>
            </div>

            <div>
              <p>Amount</p>
              <input
                className="w-32 rounded-md h-7 text-right pr-2 font-bold"
                type="number"
                value={amount}
                readOnly
                // onChange={(e) => setAmount(e.target.value)}
              ></input>
            </div>

            <button
              className="bg-[#002e62dd] border rounded border-black mt-5 w-auto px-4 h-7  text-white"
              onClick={() => {
                window.location.reload();
              }}
            >
              New
            </button>
            <button
              className="bg-[#002e62dd] border rounded mr-2 mt-5 border-black w-auto px-4 h-7  text-white"
              onClick={async () => {
                if (!buffetNo) {
                  alert("Kindly Provide buffet No");
                } else if (!selClientName) {
                  alert("Kindly Supply Customer");
                } else if (!weight) {
                  alert("Kindly Supply Gram");
                } else if (!unitRate) {
                  alert("Kindly Supply Rate/Gram");
                } else {
                  await SaveBuffet();
                  window.location.reload();
                }
              }}
            >
              Save
            </button>
          </div>

          {/* Order No PopUp */}
          {/* {orderPopUp && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] lg:w-[900px] p-1 overflow-y-auto w-[95%]">
              <div className="lg:flex flex-row mt-2">
                <p className="text-red-600 ml-2">Search:</p>
                <input
                  className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
                <p className="text-red-600 ml-2">Start Date:</p>
                <input
                  type="date"
                  className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                  value={startdate.split("T")[0]}
                ></input>
                <p className="text-red-600 ml-2">End Date:</p>
                <input
                  type="date"
                  className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  onChange={(e) => {
                    setEnddate(e.target.value);
                  }}
                  value={enddate.split("T")[0]}
                ></input>
              </div>
              <button
                className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => setOrderPopUp(false)}
              >
                Close
              </button>

              <aside className="table__pos__app text-sm ">
                <table class="custom-table">
                  <thead class="header-cell">
                    <tr>
                      <td className="pl-2">ORDER NO</td>
                      <td className="text-left">ORDER DATE</td>
                      <td className="text-left">CUSTOMER NAME</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtOrders.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setOrderPopUp(false);
                          setSelClientCode(row.CLIENTCODE);
                          setSelClientName(row["CLIENT NAME"]);
                        }}
                        className={
                          index < buffetDetails.length
                            ? "border-b cursor-pointer text-sm table-row"
                            : ""
                        }
                      >
                        <td className="text-right pr-12">
                          {row.ENQUIRYNO ? row.ENQUIRYNO : ""}
                        </td>
                        <td className="text-left">
                          {row.ENQUIRYDATE
                            ? moment(row.ENQUIRYDATE.split("T")[0]).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </td>
                        <td className="text-left">
                          {row["CLIENT NAME"] ? row["CLIENT NAME"] : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </aside>
            </div>
          )} */}
        </div>

        <div className="m-2 h-auto p-5 lg:w-[85%] mt-4 ">
          <div className="lg:flex flex-row mt-6">
            <p className="text-red-600 ml-2">Search:</p>
            <input
              className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <p className="text-red-600 ml-2">Start Date:</p>
            <input
              type="date"
              className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
              onChange={(e) => {
                setstartdate(e.target.value);
              }}
              value={startdate.split("T")[0]}
            ></input>
            <p className="text-red-600 ml-2">End Date:</p>
            <input
              type="date"
              className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
              onChange={(e) => {
                setEnddate(e.target.value);
              }}
              value={enddate.split("T")[0]}
            ></input>
          </div>
          {/* Table */}
          <aside className="mt-1 text-sm overflow-auto">
            <table class="custom-table w-[98%]">
              <thead class="header-cell">
                <tr>
                  <td className="text-right">ORDER NO</td>
                  <td className="text-left">ORDER DATE</td>
                  <td className="text-left">CLIENT NAME</td>
                  <td className="text-right">GRAMS</td>
                  <td className="text-right">RATE PER GRAM</td>
                  <td className="text-right">AMOUNT</td>
                </tr>
              </thead>
              <tbody>
                {filtBuffet?.map((item, index) => {
                  return (
                    <tr
                      className="table-row cursor-pointer"
                      key={index}
                      // onClick={() => {
                      //   console.log(item.AMOUNT);
                      //   setBuffetNo(item.ORDERNO);
                      //   setSelClientName(item.CLIENTNAME);
                      //   setSelClientCode(item.CLIENTCODE);
                      //   setWeight(item.WEIGHT);
                      //   setUnitRate(item.RATE);
                      //   setAmount(item.AMOUNT);
                      // }}
                    >
                      <td c1lassName="text-right">{item.ORDERNO}</td>
                      <td className="text-left">
                        {item.ORDERDATE
                          ? moment(item.ORDERDATE).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td className="text-left">{item.CLIENTNAME}</td>
                      <td className="text-right pr-2">{item.WEIGHT}</td>
                      <td className="text-right pr-2">
                        {item.RATE
                          ? item.RATE.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                      <td className="text-right pr-2">
                        {item.AMOUNT
                          ? item.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex justify-end gap-2 pr-5 mt-2">
              <p className="font-extrabold">Grand Total :</p>
              <span className="text-red-500 font-bold">
                {grandtotal
                  ? grandtotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""}
              </span>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default BuffetOrder;
