import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../Components/Loading";
import Navbar from "../Components/Navbar";
import io from "socket.io-client";

function ListOfOrders() {
  const [isloading, setisloading] = useState(false);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [listOfOrders, setlistOfOrders] = useState([]);
  const userState = useSelector((state) => state?.changeTheState?.user);
  const loggedInUser = userState?.emailId?.split("@")[0].toUpperCase();
  const divisionState = useSelector((state) => state.changeDivision);
  const navigate = useNavigate();
  const [clientList, setClientlist] = useState([]);

  useEffect(() => {
    getListOfClients();
    getOrderStatus();
  }, []);

  const getOrderStatus = () => {
    axios
      .get(`${BASEURL}/getOrderStatus?DIVISION=${divisionState}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        const filteredOrders = response.data.filter(
          (order) => order.PICKEDUP !== true
        );
        setlistOfOrders(filteredOrders);
        console.log(filteredOrders);
        setisloading(false);
      })

      .catch((error) => {
        console.error("Error fetching List Of Orders /getOrderStatus:", error);
      });
  };

  const getListOfClients = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/getListOfClients`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setClientlist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of /getListOfClients:", error);
        setisloading(false);
      });
  };

  const getOrderItems = (value, table, vat, client, clientCode) => {
    axios
      .get(
        `${BASEURL}/getOrderItems?ENQUIRYNO=${value}`,

        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response?.data);
        const selectedOption = clientList?.find(
          (obj) => obj["CLIENT NAME"] === client
        );
        console.log(selectedOption["CASH/CREDIT CUSTOMER"]);
        if (selectedOption["CASH/CREDIT CUSTOMER"] === "CASH") {
          navigate("/CashPOS", {
            state: {
              tableData: response?.data,
              table: table,
              totalVat: vat,
            },
          });
        } else if (selectedOption["CASH/CREDIT CUSTOMER"] === "CREDIT") {
          navigate("/CreditPOS", {
            state: {
              tableData: response?.data,
              table: table,
              totalVat: vat,
              client: client,
              clientCode: clientCode,
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching List Of Items:", error);
      });
  };

  useEffect(() => {
    const socket = io("https://marstrackbackend.azurewebsites.net"); // Establish WebSocket connection
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("orderInserted", (data) => {
      console.log("Received new order event:", data);
      // getOrderStatus();
    });

    // Clean up WebSocket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  async function PickUp(orderNo) {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/orderStatusUpdate`,
        {
          ORDERNO: Number(orderNo),
          STATUSTYPE: "PICKUP",
          STATUSUPDATEDBY: loggedInUser,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert("Picked Up Successfully");
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.error(`Error while saving Status /orderStatusUpdate`, error);
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar />
      <header className="header__of__main lg:flex justify-between items-end px-3 underline">
        <h1 className="header__of__page">LIST OF ORDERS</h1>
      </header>
      <div className="lg:flex justify-center w-full items-start lg:overflow-none overflow-auto mb-[300px] min-h-[500px]">
        <table className="table_1 " style={{ fontSize: "11px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td className="text-right">ORDER NO</td>
              <td>ORDER DATE</td>
              <td>CUSTOMER NAME</td>
              <td>STATUS</td>
              <td>TIME TAKEN</td>
              <td>INVOICE NO</td>
              <td>INVOICE</td>
              <td>ACTION</td>
            </tr>
          </thead>
          <tbody>
            {listOfOrders.length > 0 ? (
              listOfOrders.map((item, index) => (
                <tr key={index} style={{ textTransform: "uppercase" }}>
                  <td className="text-right">{item.ENQUIRYNO}</td>
                  <td>
                    {item.ENQUIRYDATE
                      ? item?.ENQUIRYDATE.slice(8, 10) +
                        "/" +
                        item?.ENQUIRYDATE.slice(5, 7) +
                        "/" +
                        item?.ENQUIRYDATE.slice(0, 4)
                      : "NA"}
                  </td>
                  <td>{item["CLIENT NAME"] ? item["CLIENT NAME"] : "N/A"}</td>
                  {/* <td>
                    {item.PREPERATIONSTATUS === 1 ? "STARTED" : "COMPLETED"}
                  </td> */}
                  <td className="text-left">
                    {item.PREPERATIONSTATUS == 0 ||
                    item.PREPERATIONSTATUS == null ||
                    item.PREPERATIONSTATUS == 1
                      ? "IN KITCHEN"
                      : item.PREPERATIONSTATUS == 2 && item.INVOICED == null
                      ? "NOT PAID"
                      : item.PREPERATIONSTATUS == 2 &&
                        item.INVOICED == true &&
                        item.PICKEDUP == null
                      ? "NOT PICKED"
                      : ""}
                  </td>
                  <td>
                    {(() => {
                      const currentDate = new Date(); // Get current date and time
                      let startDate;

                      if (item.PREPERATIONSTATUS === 2) {
                        startDate = new Date(item.PREPERATIONSTARTDATETIME); // Use PREPERATIONSTARTDATETIME if status is 2
                      } else {
                        startDate = currentDate; // Otherwise, compare to current date
                      }

                      const enquiryDate = new Date(item.ENQUIRYDATE);
                      const timeDifference = startDate - enquiryDate; // time difference in milliseconds

                      // Convert timeDifference to days, hours, and minutes
                      const totalDays = Math.floor(
                        timeDifference / (1000 * 60 * 60 * 24)
                      );
                      const totalHours = Math.floor(
                        (timeDifference % (1000 * 60 * 60 * 24)) /
                          (1000 * 60 * 60)
                      );
                      const totalMinutes = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                      );

                      return `${totalDays} days, ${totalHours} hours, ${totalMinutes} minutes`;
                    })()}
                  </td>
                  <td>{item.INVNO ? item.INVNO : "N/A"}</td>
                  <td>
                    <button
                      className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-7 cursor-pointer text-white"
                      onClick={() => {
                        if (
                          (item.PREPERATIONSTATUS == 2 ||
                            item.PREPERATIONSTATUS === 1 ||
                            item.PREPERATIONSTATUS === 0 ||
                            item.PREPERATIONSTATUS === null) &&
                          item.INVOICED == null
                        ) {
                          getOrderItems(
                            item.ENQUIRYNO,
                            item.TABLENUMBER,
                            Number(item.NETAMT) - Number(item.POGROSSAMT),
                            item["CLIENT NAME"],
                            item.CLIENTCODE
                          );
                        }
                      }}
                      disabled={
                        item.PREPERATIONSTATUS == 2 &&
                        item.INVOICED == true &&
                        item.PICKEDUP == null
                      }
                    >
                      PayBill
                    </button>
                  </td>
                  <td>
                    <button
                      className="bg-[#002e62dd] border rounded mr-2 border-black w-auto px-4 h-7  text-white cursor-pointer"
                      disabled={item.INVNO == null}
                      onClick={async () => {
                        if (
                          item.PREPERATIONSTATUS === 2 &&
                          item.INVOICED === true
                        ) {
                          await PickUp(item.ENQUIRYNO);
                          window.location.reload();
                        } else if (
                          (item.PREPERATIONSTATUS === 0 ||
                            item.PREPERATIONSTATUS === 1 ||
                            item.PREPERATIONSTATUS === null) &&
                          item.INVOICED === true
                        ) {
                          alert("This Order is In Kitchen");
                        } else {
                          alert("This Order is not yet Invoiced");
                        }
                      }}
                    >
                      Pickup
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  No Orders Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListOfOrders;
