import React, { useEffect, useState } from "react";
import axios from "axios";
import OrderTakerNavbarAllPAges from "../../Components/OrderTakerNavbarAllPAges";
import Loading from "../../Components/Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Select from "react-select";

const MyListofOrders = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(false);
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [orderHeaders, setOrderHeaders] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const divisionState = useSelector((state) => state.changeDivision);
  const userState = useSelector((state) => state.changeTheState.user);
  const loggedInUser = userState?.emailId?.split("@")[0]?.toUpperCase();
  const [status, setStatus] = useState("");

  // Total calculations
  useEffect(() => {
    let totalAmounts = 0;

    orderHeaders.forEach((item) => {
      totalAmounts += parseFloat(item.NETAMT);
    });

    setTotalAmount(totalAmounts);
  }, [orderHeaders]);

  // Filters
  const filtOrders = orderHeaders.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.ENQUIRYDATE;
    const eDate = item.ENQUIRYDATE;

    if (searchData.length === 0 && !startdate && !enddate && !status) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["CLIENT NAME", "ENQUIRYNO", "MODEOFPAY", "PONOTES"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const statusMatch =
      !status ||
      (status === "IN KITCHEN"
        ? item.PREPERATIONSTATUS == 0 ||
          item.PREPERATIONSTATUS == 1 ||
          item.PREPERATIONSTATUS == null
        : status === "NOT PAID"
        ? item.PREPERATIONSTATUS == 2 && item.INVOICED == null
        : status === "NOT PICKED"
        ? item.PREPERATIONSTATUS == 2 &&
          item.INVOICED == true &&
          item.PICKEDUP == null
        : status === "PICKED"
        ? item.PREPERATIONSTATUS == 2 &&
          item.INVOICED == true &&
          item.PICKEDUP == true
        : "");

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch && statusMatch;
  });

  useEffect(() => {
    getOrderHeaders();
  }, []);

  function getOrderHeaders() {
    setisloading(true);
    axios
      .get(
        `${BASEURL}/getOrderHeaders?DIVISION=${divisionState}&CREATEDBY=${loggedInUser}`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setOrderHeaders(res?.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log("Error while fetching /getOrderHeaders", err);
        setisloading(false);
      });
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <OrderTakerNavbarAllPAges />
      <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page">My List Of Orders</h1>
      </header>
      <section className="lg:flex items-center gap-5 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">From Date</p>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">End Date</p>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">STATUS </p>
          <Select
            className="w-48"
            value={
              status
                ? { value: status, label: status }
                : { value: "", label: "Select Status" }
            }
            onChange={(e) => {
              setStatus(e.value);
            }}
            options={[
              { value: "", label: "Select Status" },
              { value: "IN KITCHEN", label: "IN KITCHEN" },
              { value: "NOT PAID", label: "NOT PAID" },
              { value: "NOT PICKED", label: "NOT PICKED" },
              { value: "PICKED", label: "PICKED" },
            ]}
          />
        </div>
      </section>

      <div className="overflow-y-auto h-[500px] px-4">
        {filtOrders.length > 0 ? (
          <>
            <aside className="table__pos__app text-sm ">
              <table class="custom-table">
                <thead class="header-cell">
                  <tr>
                    <td className="pl-2">ORDER NO</td>
                    <td className="text-left">ORDER DATE</td>
                    <td className="text-left">CUSTOMER NAME</td>
                    <td className="text-right">NET AMOUNT</td>
                    <td className="text-left">STATUS</td>
                  </tr>
                </thead>
                <tbody>
                  {filtOrders.map((row, index) => (
                    <tr
                      key={index}
                      className={
                        index < orderHeaders.length
                          ? "border-b cursor-pointer text-sm table-row"
                          : ""
                      }
                    >
                      <td className="text-right pr-12">
                        {row.ENQUIRYNO ? row.ENQUIRYNO : ""}
                      </td>
                      <td className="text-left">
                        {row.ENQUIRYDATE
                          ? moment(row.ENQUIRYDATE.split("T")[0]).format(
                              "DD/MM/YYYY"
                            )
                          : ""}
                      </td>
                      <td className="text-left">
                        {row["CLIENT NAME"] ? row["CLIENT NAME"] : ""}
                      </td>
                      <td className="text-right pr-12">
                        {row.NETAMT
                          ? row.NETAMT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.0"}
                      </td>
                      <td className="text-left">
                        {row.PREPERATIONSTATUS == 0 ||
                        row.PREPERATIONSTATUS == null ||
                        row.PREPERATIONSTATUS == 1
                          ? "IN KITCHEN"
                          : row.PREPERATIONSTATUS == 2 && row.INVOICED == null
                          ? "NOT PAID"
                          : row.PREPERATIONSTATUS == 2 &&
                            row.INVOICED == true &&
                            row.PICKEDUP == null
                          ? "NOT PICKED"
                          : row.PREPERATIONSTATUS == 2 &&
                            row.INVOICED == true &&
                            row.PICKEDUP == true
                          ? "PICKED"
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
            <div className="flex gap-2 ml-48">
              <p className=" text-gray-700">Total Orders :</p>
              <span className="font-extrabold">
                {orderHeaders.length > 0 ? orderHeaders.length : "0"}
              </span>
              <p>|</p>
              <p className=" text-gray-700">Total Amount :</p>
              <span className="font-extrabold">
                {totalAmount
                  ? totalAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.0"}
              </span>
            </div>
          </>
        ) : (
          <div className="flex justify-center mt-4">
            <h1 className="font-extrabold text-3xl">No Orders Available</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyListofOrders;
